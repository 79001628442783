import React from "react";
import { Helmet } from "react-helmet";
import metaTagsData from "../../assets/metatags.json";

const MetaTag = ({ hostelName }) => {
  const currentHostel =
    metaTagsData.find((hostel) => hostel.hostel_name.includes(hostelName)) ||
    metaTagsData.find((hostel) => hostel.hostel_name === "Default");

  return (
    <Helmet>
      <title>{currentHostel.meta.title}</title>
      <meta name="description" content={currentHostel.meta.description} />
      <meta name="keywords" content={currentHostel.meta.keywords} />
      <meta name="rating" content={currentHostel.meta.rating} />
      <meta name="robots" content={currentHostel.meta.robots} />

      {currentHostel.meta.distribution && (
        <meta name="distribution" content={currentHostel.meta.distribution} />
      )}
      {currentHostel.meta.language && (
        <meta name="language" content={currentHostel.meta.language} />
      )}
      {currentHostel.meta.canonical && (
        <link rel="canonical" href={currentHostel.meta.canonical} />
      )}
      {currentHostel.meta.author && (
        <meta name="author" content={currentHostel.meta.author} />
      )}
      {currentHostel.meta.googleVerification && (
        <meta
          name="google-site-verification"
          content={currentHostel.meta.googleVerification}
        />
      )}

      {/* Google Tag Manager Script */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-FXMB60LY27"
      ></script>
      <script>{`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-FXMB60LY27');
      `}</script>
    </Helmet>
  );
};

export default MetaTag;
